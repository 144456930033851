import { TextInput } from "components/Inputs";
import { Button, LoadingState } from "components/Shared";
import parse from "html-react-parser";
import Datasource, { DatasourceResponse } from "lib/datasource";
import { setMergedStateWrapper } from "lib/hooks";
import _ from "lodash";
import moment from "moment";
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { CommentContext } from "./CardDynamic";
import CreateWithDocument from "./CreateWithDocument";

const ReplyToInfo = () => {
	const { context, setContext } = useContext(CommentContext);
	const setMergedContext = setMergedStateWrapper(setContext);
	const { replyToComment } = context;
	const onClear = () => setMergedContext({ replyToComment: {} });

	if (!_.get(replyToComment, "id")) return null;

	return (
		<>
			<div className="m-auto flex">
				<div className="flex-10">
					<div className={"inline-block mr-1"}>Replying to {_.get(replyToComment, "created_by.name", "")}: </div>
					<div className={"inline-block font-bold"}>{parse(replyToComment.body)}</div>
				</div>
				<div className="flex-2">
					<button type="button" className="close" aria-label="Close" onClick={onClear}>
						<span aria-hidden="true">×</span>
					</button>
				</div>
			</div>
		</>
	);
};

const SubmitButton = ({ datasource, globalId, body, metadata, textAvailable }: any) => {
	const { context } = useContext(CommentContext);
	const { replyToComment } = context;
	const onSubmit = () => {
		datasource.post(
			"v2",
			`comments`,
			{
				body: {
					global_id: globalId,
					body: body,
					reply_to_id: _.get(replyToComment, "id", null),
					metadata: metadata
				}
			},
			{ updateIsLoding: true }
		);
	};

	return <Button className={`${textAvailable ? '': 'bg-black-5 text-black-20 pointer-events-none'}`} kind="send" onClick={onSubmit} />;

};

const Create = ({ globalId, taggableUsers }: any) => {
	const { context, setContext } = useContext(CommentContext);
	const { replyToComment } = context;
	const textInputRef = useRef(null as any);
	const setMergedContext = setMergedStateWrapper(setContext);
	const [metadata, setMetadata] = useState({} as any);
	const [body, setBody] = useState<any>('');
	const [, renderTrigger] = useState(moment());
	const [datasource] = useState(new Datasource({ mainModelName: "comment", renderTrigger: renderTrigger }));
	const [uploadModalOpen, setUploadModalOpen] = useState(false);
	const[textAvailable, setTextAvailable] = useState(false);
	const onSubmitSuccess = useCallback(
		(newComment: any) => {
			setMergedContext({ highlightCommentId: newComment.id, listReload: true });
			setBody("");
			setMetadata({});
		},
		[setBody, setMetadata, setMergedContext]
	);

	useEffect(() => {
		let sub = datasource.responseSubject$.subscribe((response: DatasourceResponse) => {
			if (response.success) onSubmitSuccess(response.normalizedMainModelResponse);
		});
		return () => sub.unsubscribe();
	}, [datasource, onSubmitSuccess]);

	useEffect(() => {
		if(!_.isEmpty(replyToComment)) textInputRef.current?.focus();
	}, [replyToComment, textInputRef]);

	if (datasource.isLoading) return <LoadingState />;

	return (
		<div className="new-comment-form mt-2">
			<ReplyToInfo />
			<TextInput
				ref={child => (textInputRef.current = child)}
				users={taggableUsers}
				body={body}
				hint="Use an @ tag followed by a person's name to mention them in a comment.
					Use the 'All' options if you want everyone on this task to be tagged in a comment."
				onChange={(body: string) => {
					setBody(body);
					if(body !== '<p></p>'){
						setTextAvailable(true);
					}
					else {
						setTextAvailable(false);
					}
				}}
				spellCheck={true}
			/>
			<button
				className="button btn btn-add btn-primary"
				type="button"
				onClick={() => setUploadModalOpen(!uploadModalOpen)}
			>
				<i className="fas fa-plus" />
			</button>
			<SubmitButton textAvailable={textAvailable} globalId={globalId} body={body} metadata={metadata} datasource={datasource} /> 
			<CreateWithDocument
				globalId={globalId}
				comment={body}
				users={taggableUsers}
				isOpen={uploadModalOpen}
				onClose={() => setUploadModalOpen(false)}
				onSubmitSuccess={onSubmitSuccess}
			/>
		</div>
	);
};

export default memo(Create);
