import classNames from "classnames";
import LocalNotification from "lib/Notification";
import _ from "lodash";
import qs from "query-string";
import React, { memo, useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useHistory } from "react-router-dom";
import { Board, Button } from "v2/components/shared";
import { Form, Item as FormItem } from "v2/components/shared/Form";
import { useAddClassNames } from "v2/hooks";
import { getValidationStatus, isEmail } from "v2/utils";
import { setSmidgeUser } from "v2/utils/session";
import axios from "axios";

interface ISignInProps {
	fromUrl?: string;
}

const GoogleOAuthURI = () =>
	`${process.env.REACT_APP_API_ENDPOINT_V1}auth/google_oauth2?redirect_uri=${process.env.REACT_APP_API_ENDPOINT_V1}omniauth`

const SignIn = (props: RouteComponentProps & ISignInProps) => {
	useAddClassNames("input", ["font-mont"]);

	const { location } = props;
	const history = useHistory();
	const [shouldReload, setShouldReload] = useState(false);
	const { email: predefinedEmail } = qs.parse(location.search);
	const [data, setData] = useState({ email: predefinedEmail as string, password: "" });
	const [error, setError] = useState<{ [key: string]: string }>({});
	const [loading, setLoading] = useState(false);
	const [microsoftAuthUri, setMicrosoftAuthUri] = useState("");
	const validated = getValidationStatus(data, error, ["email", "password"]);
	const updateSmidgeUser = useCallback((cogUser: any) => {
		setSmidgeUser({
			id: cogUser.id,
			email: cogUser.email,
			name: cogUser.name,
			attributes: { phone_number: cogUser.phone_number }
		});
	}, []);

	const googleSignIn = () => {
		setLoading(true);
		window.open(GoogleOAuthURI(), "_self")
	}

	const microsoftSignIn = () => {
		setLoading(true);
		window.open(microsoftAuthUri, "_self")
	}

	function createCookie(name: any, value: any, minutes: any) {
		var expires;		
		if (minutes) {
			var date = new Date();
			date.setTime(date.getTime() + (minutes * 60 * 1000));
			expires = "; expires=" + date.toUTCString();
		} else {
			expires = "";
		}		
		document.cookie = name + "=" + value + expires + "; path=/";
	}

	const onSignIn = async () => {
		setLoading(true);
		axios.post(`${process.env.REACT_APP_API_ENDPOINT_V2}sign_in`, {
				user: {
					email: data.email, 
					password: data.password
				},
			})
			.then((res) => {
				// if (!(res.status >= 200 && res.status < 300)) {
				// 	setLoading(false);
				// 	setError({ ...error, email: "We don't recognise your email or password!" });
				// }			
				createCookie('OAUTH', res.data.data.token, 60);
				localStorage.setItem('userId', res.data.data.user.id);
				updateSmidgeUser(res.data.data.user);
				setTimeout(() => {
					setLoading(false);
					if(props.fromUrl){
						window.location.href = props.fromUrl;
						setShouldReload(true);
					}else{
						history.push("/")
					}

				}, 200);
			})
			.catch((e) => {
				if (e.response.status === 401) {
					setLoading(false);
					setError({ ...error, email: "We don't recognise your email or password!" });
				} else {
					console.error('Error:', e);
					setLoading(false);
					setError({ ...error, email: "Can't process your request" });
				}
			});
	};

	const onResetPWD = () => {
		history.push("/password/reset");
	};

	useEffect(() => {
		// Used for Google Authentication
		const searchParams = new URLSearchParams(window.location.search);
		const auth = searchParams.get('auth');
		const userId = searchParams.get('resCode');
		const cookie = searchParams.get('cookie');
		const email = searchParams.get('email');
		const msg = searchParams.get('msg');

		// User authenticated
		if (auth === "true" && userId) {
			createCookie('OAUTH', cookie, 60);
			localStorage.setItem('userId', userId);
			history.push('/')
		}
		else if (auth === "false") {
			var error_msg = email === null ? `Email does not exist` : `${email} does not exist`
				if (msg !== null) error_msg = msg
			new LocalNotification(error_msg, "", "error");
		}

		let { reason, reload } = _.get(location, "state", {}) as any;
		if (reload || shouldReload) {
			let newState = { ...(location.state as any), reload: false };
			history.replace({ ...history.location, ...{ state: newState } });
			setShouldReload(false);
			window.location.reload(); // history.go(0) may not reload page in firebox or safari, thus we specific use location.reload;
		} else if (reason === "timeout") new LocalNotification("Your session is expired.", "", "warning");
	}, [location, history, shouldReload]);

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const samlParamValue = searchParams.get('saml');
		if (samlParamValue !== null) {
			axios.get(`${process.env.REACT_APP_API_ENDPOINT_V1}oauth2?saml=${samlParamValue}`).then((res) => {
				if (res.status === 200) {
					setMicrosoftAuthUri(res.data.saml_sso_uri)
				}
			})
		}
	}, [location.search]);

	return (
		<Board className="lg:min-w-8 lg:w-1/5 lg:max-w-screen-xl">
			<div className="py-4 px-7 lg:py-10 lg:relative lg:px-0 lg:w-3/4 lg:mx-auto">
				<Form title="Sign In" data={data} error={error} setError={setError} setData={setData} onSubmit={onSignIn}>
					<FormItem
						type="email"
						name="email"
						label="Work Email"
						placeholder="Charlotte@workemail.com"
						rules={[{ message: "Please input a valid Email address.", validator: (email: string) => isEmail(email) }]}
					/>
					<FormItem
						type="password"
						name="password"
						label="Password"
						hint="All symbols are allowed. Minimum of 8 characters."
						rules={[
							{
								message: "Password should be at lease 8 characters.",
								validator: (password: string) => password.length >= 8
							}
						]}
					/>
				</Form>
				<Button
					label="Sign In"
					className={classNames("w-full text-white mt-10", {
						"bg-blue-100 hover:shadow-hover  shadow-button": validated && !loading,
						"cursor-not-allowed bg-black-5 text-black-20  font-semibold": !validated && !loading,
						"bg-transparent": !!loading
					})}
					onClick={onSignIn}
					disabled={!validated}
					loading={loading}
				/>
				<div className="w-full flex justify-center">
					<Button label="Reset Password" className="text-black-60 font-semibold mx-auto mt-5" onClick={onResetPWD} />
				</div>
				
				<div>
					<button onClick={googleSignIn} className="text-14 font-semibold leading-5 h-10 rounded-2 focus:outline-none flex items-center justify-center w-full text-white mt-10 bg-blue-100 hover:shadow-hover  shadow-button">
						<img className="w-6 h-6 m-2" src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
							loading="lazy" alt="google logo"/>
						<span className="m-2">Sign in with Google</span>
					</button>
				</div>				

				{microsoftAuthUri && (
					<div>
						<button onClick={microsoftSignIn} className="text-14 font-semibold leading-5 h-10 rounded-2 focus:outline-none flex items-center justify-center w-full text-white mt-2 bg-blue-100 hover:shadow-hover  shadow-button">
							<img className="w-6 h-6 m-2" src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Microsoft_icon.svg/120px-Microsoft_icon.svg.png"
								loading="lazy" alt="google logo"/>
							<span className="m-2">Sign in with Microsoft</span>
						</button>
					</div>
				)}
			</div>
		</Board>
	);
};

export default memo(withRouter(SignIn));
